<template>
  <div class="password-login">
    <div class="password-login-view">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="account">
          <!-- <p class="password-login-view-title">{{ $t('mobile') + '/' + $t('email') }}</p> -->
		  <p class="password-login-view-title">{{ $t('mobile') }}</p>
          <div class="password-login-view-content password-login-view-password">
            <el-input v-model="form.account" :placeholder="$t('placeholder')+$t('mobile')"></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="password">
          <p class="password-login-view-title title-top">{{ $t('password') }}</p>
          <div class="password-login-view-content password-login-view-password">
            <el-input type="password" v-model="form.password"
                      :placeholder="$t('placeholder')+$t('password')"></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="submit-login" type="primary" @click="submitForm" :loading="loading">{{
              $t('login')
            }}
          </el-button>
        </el-form-item>
      </el-form>
      <p class="login-option">
        <span class="pointer" @click="forgetPassword">{{ $t('forgotPassword') }}</span> |
        <span @click="changRegister" class="pointer">{{ $t('signUpNow') }}</span></p>
    </div>
	<!-- 登陆成功弹窗 -->
	<LoginSucceed ref="popupLogin" @confirm="confirmLogin"></LoginSucceed>
  </div>
</template>

<script>
import {login} from "@/api/User";
import {setUserInfo} from "@/utils/common/user";
import {getToken} from "@/utils/common";
import LoginSucceed from "@/components/popup/LoginSucceed.vue"

export default {
  props: {},
  data() {
    return {
      form: {
        account: '',
        password: ''
      },
      rules: {
        account: [
          {
            required: true,
            message: this.$t('placeholder') + this.$t('mobile'),
            trigger: 'blur'
          },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        password: [
          {required: true, message: this.$t('placeholder') + this.$t('password'), trigger: 'blur'}
        ]
      },
      loading: false,
	  userInfo: {}
    }
	
  },
  components: {
	  LoginSucceed
  },
  computed: {},
  created() {
  },
  mounted() {
    if (getToken()) {
      this.$router.push({
        path: '/recommend'
      })
    }
  },
  watch: {},
  methods: {
    setUserInfo,
    changRegister() {
      this.$router.push({
        path: '/register/mobileregister'
      })
    },
    forgetPassword(){
      this.$router.push({
        path: '/reset/resetpassword'
      })
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          login(this.form).then(res => {
            this.loading = false
            console.log('登录成功:', res)
            // this.$message({
            //   message: res.msg,
            //   type: 'success'
            // });
			let first_login = res.data.first_login
			this.userInfo = res.data
			if(first_login != 0){
				this.$refs.popupLogin.open()
			}else {
				this.setUserInfo(this.userInfo)
				this.$router.push({
					  path: '/recommend'
					})
			}
            this.$refs['form'].resetFields();
			// this.userInfo = res.data
			
            
			// setTimeout(() => {
			// 	this.$router.push({
			// 	  path: '/recommend'
			// 	})
			// },2000)
            
          }).catch(err => {
            this.loading = false
            console.log('登录失败:', err)
          })
        } else {
          return false;
        }
      });
    },
	confirmLogin(){
		this.setUserInfo(this.userInfo)
		this.$router.push({
			  path: '/recommend'
			})
	}
  }
}
</script>

<style scoped lang='less'>
.password-login {
  margin-top: 23px;
  padding: 60px;
  background-color: @recommendMusicianListBGColor;
  border-radius: 6px;

  &-view {
    width: 300px;
    margin: 0 auto;

    &-title {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @buttonTextColor;
      line-height: 20px;
    }

    .title-top {
      margin-top: 40px;
    }

    ::v-deep .el-form-item {
      margin-bottom: 0;
    }

    &-content {
      width: 300px;
      height: 42px;
      margin-top: 10px;
      background-color: @loginInputBgColor;
      border-radius: 4px;

      ::v-deep .el-input__inner {
        border: none;
        line-height: 42px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: @buttonTextColor;
        padding: 0;
        background-color: rgba(255, 255, 255, 0);
      }
    }

    &-password {
      display: flex;
      justify-content: space-between;

      ::v-deep .el-input__inner {
        padding-left: 12px;
      }
    }

    .submit-login {
      width: 100%;
      margin-top: 40px;
    }

    .login-option {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: @buttonTextColor;
      line-height: 22px;
      text-align: center;
      margin-top: 24px;
    }

    ::v-deep .el-button--primary {
      background-color: #3370FF !important;
      border-color: #3370FF !important;

      &:hover {
        opacity: .8;
      }
    }
  }
}
</style>
